<template>
  <div :class="[{ 'with-content': error.length }, 'form-error']">
    {{ error }}
  </div>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    error: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style lang="scss" scoped>
.form-error {
  @apply text-white w-full;
  line-height: 3em;
  background-color: rgba(25, 49, 83, 0.8);
  transform: scaleY(0);
  transition: all 0.3s linear;

  &.with-content {
    transform: scaleY(1);
  }
}
</style>
