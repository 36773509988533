<template>
  <div id="contactView">
    <SimpleContent
      v-if="simpleContentData"
      :simple-content-data="simpleContentData"
    />
    <ContactForm />
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from 'vuex'
import SimpleContent from '@/components/UI/SimpleContent'
import ContactForm from '@/components/Forms/ContactForm'
import helpers from '@/api/helpers/helpers'
export default {
  name: 'Contact',
  components: { ContactForm, SimpleContent },
  data() {
    return {
      simpleContentData: null,
    }
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
  beforeMount() {
    this.loadContactView()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadContactView() {
      this.setContactData(helpers.readStoredResponse('view.contact'))

      api.views.loadContact().then((response) => {
        this.setContactData(response)
      })
    },
    setContactData(response) {
      if (!response || !response.entry) return

      this.simpleContentData = response.entry.simpleContent[0] || null

      setTimeout(() => {
        this.setPageLoadingFalse()
      }, 300)
    },
  },
}
</script>

<style lang="scss" scoped></style>
