<template>
  <div class="contact-form-container">
    <div class="container">
      <FormSuccess v-if="submitted" class="container" />
      <form
        v-else
        action="#"
        @submit.prevent="submitContactForm"
        id="contactForm"
      >
        <div class="form-input full">
          <FormError :error="error" />
        </div>

        <div class="form-input half pr">
          <label for="first">Name*</label>
          <input
            type="text"
            id="first"
            name="first"
            placeholder="First"
            v-model="firstName"
            required
          />
        </div>

        <div class="form-input half pl">
          <label for="last" class="sr-only">Last Name</label>
          <input
            type="text"
            id="last"
            name="last"
            placeholder="Last"
            v-model="lastName"
            required
          />
        </div>

        <div class="form-input half pr">
          <label for="email" class="sr-only">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="example@email.com"
            v-model="fields.fromEmail"
            required
          />
        </div>

        <div class="form-input half pl">
          <label for="phone" class="sr-only">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="(555)555-0123"
            v-model="fields.message.phoneNumber"
          />
        </div>

        <div class="form-input full">
          <label for="message" class="sr-only">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="How can we help?"
            rows="8"
            v-model="fields.message.body"
            required
          ></textarea>
        </div>

        <div class="button-group">
          <button>Send</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { stringify } from 'qs'
import FormError from '@/components/Forms/FormError'
import FormSuccess from '@/components/Forms/FormSuccess'
export default {
  name: 'ContactForm',
  components: { FormSuccess, FormError },
  props: {
    subject: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      fields: {
        action: 'contact-form/send',
        subject: 'Contact Form Submission',
        fromName: '',
        fromEmail: '',
        message: {
          fullName: '',
          phoneNumber: '',
          body: '',
        },
      },
      error: '',
      submitting: false,
      submitted: false,
    }
  },
  methods: {
    submitContactForm() {
      if (this.submitting) return

      this.submitting = true
      this.error = ''

      if (window.Craft) {
        this.fields[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue
      }

      if (this.subject.length) {
        this.fields.subject = this.subject
      }

      this.fields.message.fullName = this.firstName + ' ' + this.lastName

      this.axios
        .post('/', stringify(this.fields))
        .then(() => {
          this.submitted = true
        })
        .catch((error) => {
          const response = error.response

          if (response && response.data && response.data.error) {
            this.error = response.data.error
          } else {
            this.error =
              'Something went wrong while trying to submit the form. Please try again.'
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-form-container {
  .container {
    #contactForm {
      @apply flex flex-wrap mx-8 mb-36;

      .form-input {
        @apply flex flex-col justify-end items-start mb-10;

        &.half {
          @apply w-full;
        }

        &.full {
          @apply w-full;
        }

        &.pr {
          @apply pr-0;
        }

        &.pl {
          @apply pl-0;
        }

        label {
          @apply block;
          font-family: 'Inter SemiBold', sans-serif;
          color: #193153;
        }

        input,
        textarea {
          @apply block w-full p-2;
          border: 1px solid #193153;
          color: #193153;
          transition: all 0.3s ease-in;

          &:focus {
            border: 1px solid rgba(25, 49, 83, 0.5);
            outline: none;
          }
        }
      }

      .button-group {
        @apply w-full flex justify-end;

        button {
          @apply text-white px-6 py-3 font-semibold uppercase;
          font-family: 'Inter SemiBold', sans-serif;
          font-size: 1em;
          line-height: 1.5em;
          letter-spacing: 1px;
          background-color: #193153;
          transition: all 0.3s ease-in;

          &:hover,
          &:focus {
            background-color: rgba(25, 49, 83, 0.8);
          }
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      #contactForm {
        @apply mx-20 mb-36;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      #contactForm {
        .form-input {
          &.half {
            @apply w-1/2;
          }

          &.pr {
            @apply pr-3;
          }

          &.pl {
            @apply pl-3;
          }
        }
      }
    }
  }
}
</style>
